// @flow
import React from 'react'
import {
  MDBSideNavLink,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon
} from 'mdbreact'
import bg from '../assets/img/sidenav.jpg'
import logo from '../assets/img/logo-mdbreact.png'

type SideNavigationProps = {
  onLinkClick: any,
  triggerOpening: any,
  breakWidth: number
}

const SideNavigation = ({ onLinkClick, triggerOpening, breakWidth }: SideNavigationProps) => {
  const rSNL = (to: string, text: string) =>
    <MDBSideNavLink to={to} onClick={onLinkClick}>
      {text}
    </MDBSideNavLink>

  return (
    <div className='white-skin'>
      <MDBSideNav
        href="/"
        logo={logo}
        bg={bg}
        mask='strong'
        fixed
        breakWidth={breakWidth}
        triggerOpening={triggerOpening}
        style={{ transition: 'padding-left 0.3s' }}
      >

        <MDBSideNavNav>
          <MDBSideNavCat
            name='Dashboards'
            id='dashboard-cat'
            icon='tachometer-alt'
          >
            {rSNL('/admin/dashboards/v1', 'Version 1')}
            {rSNL('/admin/dashboards/v2', 'Version 2')}
            {rSNL('/admin/dashboards/v3', 'Version 3')}
            {rSNL('/admin/dashboards/v4', 'Version 4')}
            {rSNL('/admin/dashboards/v5', 'Version 5')}
            {rSNL('/admin/dashboards/v6', 'Version 6')}
          </MDBSideNavCat>

          <MDBSideNavCat name='Pages' id='pages-cat' icon='image'>
            {rSNL('/admin/pages/login', 'Login')}
            {rSNL('/admin/pages/register', 'Register')}
            {rSNL('/admin/pages/pricing', 'Pricing')}
            {rSNL('/admin/pages/lock', 'Lock')}
            {rSNL('/admin/pages/about', 'About us')}
            {rSNL('/admin/pages/post', 'Single post')}
            {rSNL('/admin/pages/posts', 'Post listing')}
            {rSNL('/admin/pages/landing', 'Landing page')}
            {rSNL('/admin/pages/customers', 'Customers')}
            {rSNL('/admin/pages/invoice', 'Invoice')}
            {rSNL('/admin/pages/chat', 'Chat')}
            {rSNL('/admin/pages/support', 'Support')}
            {rSNL('/admin/pages/page-creator', 'Page creator')}
          </MDBSideNavCat>

          <MDBSideNavCat name='Profile' id='profile-cat' icon='user'>
            {rSNL('/admin/profile/v1', 'Basic 1')}
            {rSNL('/admin/profile/v2', 'Basic 2')}
            {rSNL('/admin/profile/extended', 'Extended')}
          </MDBSideNavCat>

          <MDBSideNavCat name='CSS' id='css-cat' iconBrand icon='css3'>
            {rSNL('/admin/css/grid', 'Grid system')}
            {rSNL('/admin/css/media', 'Media object')}
            {rSNL('/admin/css/utilities', 'Utilities')}
            {rSNL('/admin/css/code', 'Code')}
            {rSNL('/admin/css/icons', 'Icons')}
            {rSNL('/admin/css/images', 'Images')}
            {rSNL('/admin/css/typography', 'Typography')}
            {rSNL('/admin/css/colors', 'Colors')}
            {rSNL('/admin/css/masks', 'Masks')}
            {rSNL('/admin/css/hover', 'Hover effects')}
            {rSNL('/admin/css/shadows', 'Shadows')}
            {rSNL('/admin/css/animations', 'Animations')}
          </MDBSideNavCat>

          <MDBSideNavCat name='Components' id='components-cat' icon='th'>
            {rSNL('/admin/components/buttons', 'Buttons')}
            {rSNL('/admin/components/cards', 'Cards')}
            {rSNL('/admin/components/list', 'List group')}
            {rSNL('/admin/components/panels', 'Panels')}
            {rSNL('/admin/components/progress', 'Progress bars')}
            {rSNL('/admin/components/popovers', 'Popovers')}
            {rSNL('/admin/components/tooltips', 'Tooltips')}
            {rSNL('/admin/components/tabs', 'Tabs & pills')}
            {rSNL('/admin/components/tags', 'Tags, labels & badges')}
            {rSNL('/admin/components/date', 'Date picker')}
            {rSNL('/admin/components/time', 'Time picker')}
            {rSNL('/admin/components/stepper', 'Stepper')}
            {rSNL('/admin/components/pagination', 'Pagination')}
            {rSNL('/admin/components/collapse', 'Collapse')}
          </MDBSideNavCat>

          <MDBSideNavCat name='Forms' id='forms-cat' icon='check-square'>
            {rSNL('/admin/forms/basic', 'Basic')}
            {rSNL('/admin/forms/extended', 'Extended')}
            {rSNL('/admin/forms/validation', 'Validation')}
          </MDBSideNavCat>

          <MDBSideNavCat name='Tables' id='tables-cat' icon='table'>
            {rSNL('/admin/tables/basic', 'Basic')}
            {rSNL('/admin/tables/extended', 'Extended')}
            {rSNL('/admin/tables/datatable', 'Datatable')}
          </MDBSideNavCat>

          <MDBSideNavCat name='Maps' id='maps-cat' icon='map'>
            {rSNL('/admin/maps/google', 'Google')}
            {rSNL('/admin/maps/full', 'Full screen map')}
            {rSNL('/admin/maps/vector', 'Vector world map')}
          </MDBSideNavCat>

          <MDBSideNavLink topLevel to='/admin/alerts' onClick={onLinkClick}>
            <MDBIcon icon='bell mr-2' />
              Alerts
          </MDBSideNavLink>

          <MDBSideNavLink topLevel to='/admin/modals' onClick={onLinkClick}>
            <MDBIcon icon='bolt mr-2' />
              Modals
          </MDBSideNavLink>

          <MDBSideNavLink topLevel to='/admin/charts' onClick={onLinkClick}>
            <MDBIcon icon='chart-pie mr-2' />
              Charts
          </MDBSideNavLink>

          <MDBSideNavLink topLevel to='/admin/calendar' onClick={onLinkClick}>
            <MDBIcon icon='calendar-check mr-2' />
              Calendar
          </MDBSideNavLink>

          <MDBSideNavLink topLevel to='/admin/sections' onClick={onLinkClick}>
            <MDBIcon icon='th-large mr-2' />
              Sections
          </MDBSideNavLink>
        </MDBSideNavNav>

      </MDBSideNav>
    </div>
  )
}

export default (SideNavigation: SideNavigationProps => React$Node)
