// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'

// MDB Admin Pro
import DV1 from '../components/dashboard/v1'
import DV2 from '../components/dashboard/v2'
import DV3 from '../components/dashboard/v3'
import DV4 from '../components/dashboard/v4'
import DV5 from '../components/dashboard/v5'
import DV6 from '../components/dashboard/v6'

import Customers from '../components/pages/Customers'
import Invoice from '../components/pages/Invoice'
import Chat from '../components/pages/Chat'
import Support from '../components/pages/Support'
import PageCreator from '../components/pages/PageCreator.js'

import PV1 from '../components/profile/v1'
import PV2 from '../components/profile/v2'
import PExtended from '../components/profile/extended'

import Google from '../components/maps/google'
import MFull from '../components/maps/full'
import Vector from '../components/maps/vector'

import TBasic from '../components/tables/basic'
import TExtended from '../components/tables/extended'
import Datatable from '../components/tables/datatable'

import Grid from '../components/css/grid'
import Utilities from '../components/css/utilities'
import Code from '../components/css/code'
import Icons from '../components/css/icons'
import Images from '../components/css/images'
import Typography from '../components/css/typography'
import Colors from '../components/css/colors'
import Shadows from '../components/css/shadows'
import Masks from '../components/css/masks'
import Hover from '../components/css/hover'
import MediaObject from '../components/css/media'
import Animations from '../components/css/animations'

import Buttons from '../components/components/buttons'
import Cards from '../components/components/cards'
import Panels from '../components/components/panels'
import List from '../components/components/list'
import Progress from '../components/components/progress'
import Tabs from '../components/components/tabs'
import Tags from '../components/components/tags'
import DatePicker from '../components/components/datepicker'
import TimePicker from '../components/components/timepicker'
import Popover from '../components/components/popover'
import Tooltip from '../components/components/tooltips'
import Stepper from '../components/components/stepper'
import Pagination from '../components/components/pagination'
import Collapse from '../components/components/collapse'

import FBasic from '../components/forms/basic'
import FExtended from '../components/forms/extended'
import FValidation from '../components/forms/validation'

import Charts from '../components/others/charts'
import Alerts from '../components/others/alerts'
import Modals from '../components/others/modals'
import Sections from '../components/others/sections'
import Calendar from '../components/others/calendar'

// MDB Pro
import NavigationNavPage from '../pages/NavigationNavPage'
import FormsNavPage from '../pages/FormsNavPage'
import TablesNavPage from '../pages/TablesNavPage'
import AddonsNavPage from '../pages/AddonsNavPage'
import ModalsNavPage from '../pages/ModalsNavPage'
import AdvancedNavPage from '../pages/AdvancedNavPage'
import ComponentsNavPage from '../pages/ComponentsNavPage'

// MDB FREE
import AnimationPage from '../pages/AnimationPage'
import AlertPage from '../pages/AlertPage'
import HomePage from '../pages/HomePage'
import ButtonPage from '../pages/ButtonPage'
import CSSNavPage from '../pages/CSSNavPage'
import TablePage from '../pages/TablePage'
import TableResponsivePage from '../pages/TableResponsivePage'
import TableScrollPage from '../pages/TableScrollPage'
import TableStylesPage from '../pages/TableStylesPage'
import BadgePage from '../pages/BadgePage'
import BreadcrumbPage from '../pages/BreadcrumbPage'
import FaPage from '../pages/FaPage'
import DatatablePage from '../pages/DatatablePage'
import DatatableApiPage from '../pages/DatatableApiPage'
import ModalPage from '../pages/ModalPage'
import ModalFormPage from '../pages/ModalFormPage'
import ModalExamplesPage from '../pages/ModalExamplesPage'
import ProgressPage from '../pages/ProgressPage'
import InputPage from '../pages/InputPage'
import MediaPage from '../pages/MediaPage'
import JumbotronPage from '../pages/JumbotronPage'
import CardsPage from '../pages/CardsPage'
import PaginationPage from '../pages/PaginationPage'
import PopoverPage from '../pages/PopoverPage'
import ListGroupPage from '../pages/ListGroupPage'
import CarouselPage from '../pages/CarouselPage'
import PanelPage from '../pages/PanelPage'
import CollapsePage from '../pages/CollapsePage'
import TooltipsPage from '../pages/TooltipsPage'
import FooterPage from '../pages/FooterPage'
import MasksPage from '../pages/MasksPage'
import DropdownPage from '../pages/DropdownPage'
import VideoCarouselPage from '../pages/VideoCarouselPage'
import HoverPage from '../pages/HoverPage'
import FormsPage from '../pages/FormsPage'
import ChartsPage from '../pages/ChartsPage'
import SearchPage from '../pages/SearchPage'
import ValidationPage from '../pages/ValidationPage'
import NavbarPage from '../pages/NavbarPage'
import IframePage from '../pages/IframePage'
import EdgeHeaderPage from '../pages/EdgeHeaderPage'
import SpinnerPage from '../pages/SpinnerPage'
import MasonryPage from '../pages/MasonryPage'
import ScrollBarPage from '../pages/ScrollBarPage'
import NavsPage from '../pages/NavsPage'
import TabsPage from '../pages/TabsPage'
import PillsPage from '../pages/PillsPage'
import NotificationPage from '../pages/NotificationPage'
import InputGroupPage from '../pages/InputGroupPage'
import TreeviewPage from '../pages/TreeviewPage'
import RatingPage from '../pages/RatingPage'

// MDB PRO
import SectionsNavPage from '../pages/pro/sections/SectionsNavPage'
import MaterialDropdownPage from '../pages/pro/DropdownPage'
import AutocompletePage from '../pages/pro/AutocompletePage'
import ButtonPagePro from '../pages/pro/ButtonPage'
import ChartsPagePro from '../pages/pro/ChartsPage'
import ChatPage from '../pages/pro/ChatPage'
import eCommercePage from '../pages/pro/eCommercePage'
import ChipsPage from '../pages/pro/ChipsPage'
import InputPagePro from '../pages/pro/InputPage'
import CollapsePagePro from '../pages/pro/CollapsePage'
import ScrollBarPagePro from '../pages/pro/ScrollBarPage'
import ScrollSpyPage from '../pages/pro/ScrollSpyPage'
import SelectPage from '../pages/pro/SelectPage'
import SideNavPage from '../pages/pro/SideNavPage'
import SlimSideNavPage from '../pages/pro/SlimSideNavPage'
import DatePickerPage from '../pages/pro/DatePickerPage'
import DoubleNavigationPage from '../pages/pro/DoubleNavigationPage'
import TimePickerPage from '../pages/pro/TimePickerPage'
import StickyPage from '../pages/pro/StickyPage'
import LightboxPage from '../pages/pro/LightboxPage'
import MultiCarouselPage from '../pages/pro/MultiCarouselPage'
import NotificationPagePro from '../pages/pro/NotificationPage'
import SpinnerPagePro from '../pages/pro/SpinnerPage'
import TabsPagePro from '../pages/pro/TabsPage'
import ThumbnailsCarousel from '../pages/pro/ThumbnailsCarousel'
import TestimonialsPage from '../pages/pro/sections/TestimonialsPage'
import EcommercePage from '../pages/pro/sections/EcommercePage'
import AppPage from '../pages/pro/sections/AppPage'
import ContactFormPage from '../pages/pro/sections/ContactFormPage'
import ClassicFormPage from '../pages/pro/sections/ClassicFormPage'
import VideoBackgroundPage from '../pages/pro/sections/VideoBackgroundPage'
import ProjectsPage from '../pages/pro/sections/ProjectsPage'
import FeaturesPage from '../pages/pro/sections/FeaturesPage'
import ContactPage from '../pages/pro/sections/ContactPage'
import SocialButtonsPage from '../pages/pro/SocialButtonsPage'
import StepperPage from '../pages/pro/StepperPage'
import BlogPage from '../pages/pro/sections/BlogPage'
import TeamPage from '../pages/pro/sections/TeamPage'
import MagazinePage from '../pages/pro/sections/MagazinePage'
import SocialPage from '../pages/pro/sections/SocialPage'
import FormsPagePro from '../pages/pro/FormsPage'
import CardsPagePro from '../pages/pro/CardsPage'
import SearchPagePro from '../pages/pro/SearchPage'
import FooterPagePro from '../pages/pro/FooterPage'
import TableEditable from '../pages/pro/TableEditablePage'
import DatatableCsvPage from '../pages/pro/DatatableCsvPage'
import ModalFormProPage from '../pages/pro/ModalFormPage'
import HabmburgerMenuPage from '../pages/pro/HabmburgerMenuPage'
import TimelinePage from '../pages/pro/TimelinePage'
import SliderPage from '../pages/pro/SliderPage'
import StreakPage from '../pages/pro/StreakPage'
import ValidationPagePro from '../pages/pro/ValidationPage'
import FlippingCardsPage from '../pages/pro/FlippingCardsPage'
import TableStylesPagePro from '../pages/pro/TableStylesPage'
import ModalExamplesProPage from '../pages/pro/ModalExamplesPage'
import BlogComponentsPage from '../pages/pro/BlogComponentsPage'
import SmoothScrollPage from '../pages/pro/SmoothScrollPage'
import NavsPagePro from '../pages/pro/NavsPage'
import MinimalisticIntro from '../pages/pro/sections/MinimalisticIntro'
import ParallaxIntro from '../pages/pro/sections/ParallaxIntro'
import CallToActionIntro from '../pages/pro/sections/CallToActionIntro'
import PillsPagePro from '../pages/pro/PillsPage'
import InputGroupPagePro from '../pages/pro/InputGroupPage'
import GalleryPage from '../pages/pro/GalleryPage'
import DatatableFilterPage from '../pages/pro/DatatableFilterPage'
import TooltipsPagePro from '../pages/pro/TooltipsPage'

const fourtOFour = () => <h1 className="text-center">404</h1>

const RoutesWithSidebar = () =>
  <Switch>

    {/* MDB Admin Pro */}
    <Route path='/admin/dashboards/v1' component={DV1} />
    <Route path='/admin/dashboards/v2' component={DV2} />
    <Route path='/admin/dashboards/v3' exact component={DV3} />
    <Route path='/admin/dashboards/v4' exact component={DV4} />
    <Route path='/admin/dashboards/v5' exact component={DV5} />
    <Route path='/admin/dashboards/v6' exact component={DV6} />

    <Route path='/admin/pages/invoice' exact component={Invoice} />
    <Route path='/admin/pages/customers' exact component={Customers} />
    <Route path='/admin/pages/chat' exact component={Chat} />
    <Route path='/admin/pages/support' exact component={Support} />
    <Route path='/admin/pages/page-creator' exact component={PageCreator} />

    <Route path='/admin/profile/v1' exact component={PV1} />
    <Route path='/admin/profile/v2' exact component={PV2} />
    <Route path='/admin/profile/extended' exact component={PExtended} />

    <Route path='/admin/css/grid' exact component={Grid} />
    <Route path='/admin/css/utilities' exact component={Utilities} />
    <Route path='/admin/css/code' exact component={Code} />
    <Route path='/admin/css/icons' exact component={Icons} />
    <Route path='/admin/css/images' exact component={Images} />
    <Route path='/admin/css/typography' exact component={Typography} />
    <Route path='/admin/css/colors' exact component={Colors} />
    <Route path='/admin/css/shadows' exact component={Shadows} />
    <Route path='/admin/css/masks' exact component={Masks} />
    <Route path='/admin/css/hover' exact component={Hover} />
    <Route path='/admin/css/media' exact component={MediaObject} />
    <Route path='/admin/css/animations' exact component={Animations} />

    <Route path='/admin/components/buttons' exact component={Buttons} />
    <Route path='/admin/components/cards' exact component={Cards} />
    <Route path='/admin/components/panels' exact component={Panels} />
    <Route path='/admin/components/list' exact component={List} />
    <Route path='/admin/components/progress' exact component={Progress} />
    <Route path='/admin/components/tabs' exact component={Tabs} />
    <Route path='/admin/components/tags' exact component={Tags} />
    <Route path='/admin/components/date' exact component={DatePicker} />
    <Route path='/admin/components/time' exact component={TimePicker} />
    <Route path='/admin/components/popovers' exact component={Popover} />
    <Route path='/admin/components/tooltips' exact component={Tooltip} />
    <Route path='/admin/components/stepper' exact component={Stepper} />
    <Route path='/admin/components/pagination' exact component={Pagination} />
    <Route path='/admin/components/collapse' exact component={Collapse} />

    <Route path='/admin/tables/basic' exact component={TBasic} />
    <Route path='/admin/tables/extended' exact component={TExtended} />
    <Route path='/admin/tables/datatable' exact component={Datatable} />

    <Route path='/admin/maps/google' exact component={Google} />
    <Route path='/admin/maps/full' exact component={MFull} />
    <Route path='/admin/maps/vector' exact component={Vector} />

    <Route path='/admin/forms/basic' exact component={FBasic} />
    <Route path='/admin/forms/extended' exact component={FExtended} />
    <Route path='/admin/forms/validation' exact component={FValidation} />

    <Route path='/admin/charts' exact component={Charts} />
    <Route path='/admin/alerts' exact component={Alerts} />
    <Route path='/admin/modals' exact component={Modals} />
    <Route path='/admin/sections' exact component={Sections} />
    <Route path='/admin/calendar' exact component={Calendar} />

    {/* MDB Pro */}
    <Route exact path='/' component={HomePage} />
    <Route exact path='/addons' component={AddonsNavPage} />
    <Route exact path='/advanced' component={AdvancedNavPage} />
    <Route exact path='/components' component={ComponentsNavPage} />
    <Route exact path='/css' component={CSSNavPage} />
    <Route exact path='/forms' component={FormsNavPage} />
    <Route exact path='/modals' component={ModalsNavPage} />
    <Route exact path='/navigation' component={NavigationNavPage} />
    <Route exact path='/tables' component={TablesNavPage} />

    {/* MDB FREE */}
    <Route path='/addons/iframe' component={IframePage} />
    <Route path='/addons/edge-header' component={EdgeHeaderPage} />
    <Route path='/addons/notifications' component={NotificationPage} />
    <Route path='/addons/treeview' component={TreeviewPage} />
    <Route path='/addons/Rating' component={RatingPage} />
    <Route path='/advanced/carousel' component={CarouselPage} />
    <Route path='/advanced/collapse' component={CollapsePage} />
    <Route path='/advanced/videocarousel' component={VideoCarouselPage} />
    <Route path='/advanced/videocarousel' component={VideoCarouselPage} />
    <Route path='/advanced/alerts' component={AlertPage} />
    <Route path='/advanced/popover' component={PopoverPage} />
    <Route path='/advanced/tooltips' component={TooltipsPage} />
    <Route path='/advanced/charts' component={ChartsPage} />
    <Route path='/advanced/scrollbar' component={ScrollBarPage} />
    <Route path='/css/animations' component={AnimationPage} />
    <Route path='/css/icons' component={FaPage} />
    <Route path='/css/jumbotron' component={JumbotronPage} />
    <Route path='/css/masks' component={MasksPage} />
    <Route path='/css/hover' component={HoverPage} />
    <Route path='/css/masonry' component={MasonryPage} />
    <Route path='/components/media' component={MediaPage} />
    <Route path='/components/badge' component={BadgePage} />
    <Route path='/components/cards' component={CardsPage} />
    <Route path='/components/buttons' component={ButtonPage} />
    <Route path='/components/dropdown' component={DropdownPage} />
    <Route path='/components/progress' component={ProgressPage} />
    <Route path='/components/pagination' component={PaginationPage} />
    <Route path='/components/list-group' component={ListGroupPage} />
    <Route path='/components/panels' component={PanelPage} />
    <Route path='/components/search' component={SearchPage} />
    <Route path='/components/spinner' component={SpinnerPage} />
    <Route path='/components/tabs' component={TabsPage} />
    <Route path='/components/pills' component={PillsPage} />
    <Route path='/forms/forms' component={FormsPage} />
    <Route path='/forms/validation' component={ValidationPage} />
    <Route path='/forms/input' component={InputPage} />
    <Route path='/forms/inputgroup' component={InputGroupPage} />
    <Route path='/modals/modal' component={ModalPage} />
    <Route path='/modals/modal-form' component={ModalFormPage} />
    <Route path='/modals/modal-examples' component={ModalExamplesPage} />
    <Route path='/navigation/navbar' component={NavbarPage} />
    <Route path='/navigation/breadcrumb' component={BreadcrumbPage} />
    <Route path='/navigation/navs' component={NavsPage} />
    <Route path='/navigation/footer' component={FooterPage} />
    <Route path='/tables/table' component={TablePage} />
    <Route path='/tables/table-responsive' component={TableResponsivePage} />
    <Route path='/tables/table-scroll' component={TableScrollPage} />
    <Route path='/tables/table-styles' component={TableStylesPage} />
    <Route path='/tables/datatable-api' component={DatatableApiPage} />
    <Route path='/tables/datatable' component={DatatablePage} />

    {/* MDB PRO */}
    <Route exact path='/sections' component={SectionsNavPage} />
    <Route path='/addons/pro/gallery' component={GalleryPage} />
    <Route path='/addons/pro/blog-components' component={BlogComponentsPage} />
    <Route path='/addons/pro/chat' component={ChatPage} />
    <Route path='/addons/pro/e-commerce-components' component={eCommercePage} />
    <Route path='/addons/pro/flipping-cards' component={FlippingCardsPage} />
    <Route path='/addons/pro/notifications' component={NotificationPagePro} />
    <Route path='/addons/pro/streak' component={StreakPage} />
    <Route path='/addons/pro/timeline' component={TimelinePage} />
    <Route path='/advanced/pro/charts' component={ChartsPagePro} />
    <Route path='/advanced/pro/collapse' component={CollapsePagePro} />
    <Route path='/advanced/pro/lightbox' component={LightboxPage} />
    <Route path='/advanced/pro/multicarousel' component={MultiCarouselPage} />
    <Route path='/advanced/pro/tooltips' component={TooltipsPagePro} />
    <Route path='/advanced/pro/scrollbar' component={ScrollBarPagePro} />
    <Route path='/advanced/pro/smoothscroll' component={SmoothScrollPage} />
    <Route path='/advanced/pro/sticky' component={StickyPage} />
    <Route path='/advanced/pro/thumbnailscarousel' component={ThumbnailsCarousel} />
    <Route path='/components/pro/buttons' component={ButtonPagePro} />
    <Route path='/components/pro/cards' component={CardsPagePro} />
    <Route path='/components/pro/chips' component={ChipsPage} />
    <Route path='/components/pro/dropdown' component={MaterialDropdownPage} />
    <Route path='/components/pro/pills' component={PillsPagePro} />
    <Route path='/components/pro/search' component={SearchPagePro} />
    <Route path='/components/pro/socialbuttons' component={SocialButtonsPage} />
    <Route path='/components/pro/spinner' component={SpinnerPagePro} />
    <Route path='/components/pro/stepper' component={StepperPage} />
    <Route path='/components/pro/tabs' component={TabsPagePro} />
    <Route path='/forms/pro/autocomplete' component={AutocompletePage} />
    <Route path='/forms/pro/datepicker' component={DatePickerPage} />
    <Route path='/forms/pro/forms' component={FormsPagePro} />
    <Route path='/forms/pro/input' component={InputPagePro} />
    <Route path='/forms/pro/inputgroup' component={InputGroupPagePro} />
    <Route path='/forms/pro/select' component={SelectPage} />
    <Route path='/forms/pro/slider' component={SliderPage} />
    <Route path='/forms/pro/timepicker' component={TimePickerPage} />
    <Route path='/forms/pro/validation' component={ValidationPagePro} />
    <Route path='/modals/pro/modal-examples' component={ModalExamplesProPage} />
    <Route path='/modals/pro/modal-form' component={ModalFormProPage} />
    <Route path='/navigation/pro/double' component={DoubleNavigationPage} />
    <Route path='/navigation/pro/footer' component={FooterPagePro} />
    <Route path='/navigation/pro/hamburger-menu' component={HabmburgerMenuPage} />
    <Route path='/navigation/pro/navs' component={NavsPagePro} />
    <Route path='/navigation/pro/scrollspy' component={ScrollSpyPage} />
    <Route path='/navigation/pro/sidenav' component={SideNavPage} />
    <Route path='/navigation/pro/slim-sidenav' component={SlimSideNavPage} />
    <Route path='/sections/app' component={AppPage} />
    <Route path='/sections/blog' component={BlogPage} />
    <Route path='/sections/call-to-action-intro' component={CallToActionIntro} />
    <Route path='/sections/classicform' component={ClassicFormPage} />
    <Route path='/sections/contact' component={ContactPage} />
    <Route path='/sections/contactform' component={ContactFormPage} />
    <Route path='/sections/ecommerce' component={EcommercePage} />
    <Route path='/sections/features' component={FeaturesPage} />
    <Route path='/sections/magazine' component={MagazinePage} />
    <Route path='/sections/minimalistic-intro' component={MinimalisticIntro} />
    <Route path='/sections/parallax-intro' component={ParallaxIntro} />
    <Route path='/sections/projects' component={ProjectsPage} />
    <Route path='/sections/social' component={SocialPage} />
    <Route path='/sections/team' component={TeamPage} />
    <Route path='/sections/testimonials' component={TestimonialsPage} />
    <Route path='/sections/videobackground' component={VideoBackgroundPage} />
    <Route path='/tables/pro/datatable-csv' component={DatatableCsvPage} />
    <Route path='/tables/pro/datatable-filter' component={DatatableFilterPage} />
    <Route path='/tables/pro/table-styles' component={TableStylesPagePro} />
    <Route path='/tables/pro/tableeditable' component={TableEditable} />

    <Route component={fourtOFour} />
  </Switch>

export default (RoutesWithSidebar: () => React$Node)
