// @flow
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import LoginPro from './components/pages/Login'
import Register from './components/pages/Register'
import Pricing from './components/pages/Pricing'
import Lock from './components/pages/Lock'
import About from './components/pages/About'
import SinglePost from './components/pages/SinglePost'
import PostListing from './components/pages/PostListing'
import Landing from './components/pages/Landing'
import Admin from './components/Admin'

const App = () => (
  <Router>
    <Switch>
      { /* Full Pages */ }
      <Route path='/admin/pages/login' exact component={LoginPro} />
      <Route path='/admin/pages/register' exact component={Register} />
      <Route path='/admin/pages/pricing' exact component={Pricing} />
      <Route path='/admin/pages/lock' exact component={Lock} />
      <Route path='/admin/pages/about' exact component={About} />
      <Route path='/admin/pages/post' exact component={SinglePost} />
      <Route path='/admin/pages/posts' exact component={PostListing} />
      <Route path='/admin/pages/landing' exact component={Landing} />

      { /* RoutesWithSidebar */}
      <Admin />
    </Switch>
  </Router>
)

export default (App: () => React$Node)
