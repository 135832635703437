// @flow
import React from 'react'
import { MDBIcon, MDBNavLink } from 'mdbreact'

type NavLinkProps = {
  to: string,
  title: string
}

const NavLink = ({ to, title }: NavLinkProps) => {
  return (
    <MDBNavLink className='list-group-item list-group-item-action' to={to} >
      <h5 style={{ margin: '0' }} className='justify-content-between d-flex align-items-center'>
        {title}
        <MDBIcon icon='angle-right' />
      </h5>
    </MDBNavLink>
  )
}

export default (NavLink: NavLinkProps => React$Node)
