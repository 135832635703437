// @flow
import React from 'react'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBNavItem,
  MDBNavLink
} from 'mdbreact'
import { useHistory } from 'react-router'

type TopNavigationProps = {
  onSideNavToggleClick: any,
  routeName: string,
  toggle: boolean
}

const TopNavigation = ({ onSideNavToggleClick, routeName, toggle }: TopNavigationProps) => {
  const history = useHistory()
  const navStyle = {
    paddingLeft: toggle ? '16px' : '240px',
    transition: 'padding-left 0.3s'
  }

  return <MDBNavbar
    className='flexible-MDBNavbar'
    light
    expand='md'
    scrolling
    fixed='top'
    style={{ zIndex: 3 }}
  >
    <div
      onClick={onSideNavToggleClick}
      key='sideNavToggleA'
      style={{
        lineHeight: '32px',
        marginLeft: '1em',
        verticalAlign: 'middle',
        cursor: 'pointer'
      }}
    >
      <MDBIcon icon='bars' color='white' size='lg' />
    </div>

    <MDBNavbarBrand href='/' style={navStyle}>
      <strong>{routeName}</strong>
    </MDBNavbarBrand>
    <MDBNavbarNav expand='sm' right style={{ flexDirection: 'row' }}>
      <MDBDropdown>
        <MDBDropdownToggle nav caret>
          <MDBBadge color='red' className='mr-2'>
              3
          </MDBBadge>
          <MDBIcon icon='bell' />{' '}
          <span className='d-none d-md-inline'>Notifications</span>
        </MDBDropdownToggle>
        <MDBDropdownMenu right style={{ minWidth: '400px' }}>
          <MDBDropdownItem href='#!'>
            <MDBIcon icon='money-bill-alt' className='mr-2' />
              New order received
            <span className='float-right'>
              <MDBIcon icon='clock' /> 13 min
            </span>
          </MDBDropdownItem>
          <MDBDropdownItem href='#!'>
            <MDBIcon icon='money-bill-alt' className='mr-2' />
              New order received
            <span className='float-right'>
              <MDBIcon icon='clock' /> 33 min
            </span>
          </MDBDropdownItem>
          <MDBDropdownItem href='#!'>
            <MDBIcon icon='chart-line' className='mr-2' />
              Your campaign is about to end
            <span className='float-right'>
              <MDBIcon icon='clock' /> 53 min
            </span>
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
      <MDBNavItem>
        <MDBNavLink to='#'>
          <MDBIcon icon='envelope' />
          <span className='d-none d-md-inline ml-1'>Contact</span>
        </MDBNavLink>
      </MDBNavItem>
      <MDBDropdown>
        <MDBDropdownToggle nav caret>
          <MDBIcon icon='user' />{' '}
          <span className='d-none d-md-inline'>{'nombre'}</span>
        </MDBDropdownToggle>
        <MDBDropdownMenu right style={{ minWidth: '200px' }}>

          <MDBDropdownItem href='/admin/profile/v1' onClick={e => {
            history.push('/admin/profile/v1')
            e.preventDefault()
          }}>
            Mi Cuenta
          </MDBDropdownItem>

          <MDBDropdownItem onClick={() => history.push('/login')}>Cerrar sesión</MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    </MDBNavbarNav>
  </MDBNavbar>
}

export default (TopNavigation: TopNavigationProps => React$Node)
