// @flow
import React from 'react'
import { MDBFooter } from 'mdbreact'

type CopyrightsProps = {
  className: string,
  style: any
}
const Copyrights = ({ className, style }: CopyrightsProps) => {
  const date = new Date().getFullYear()
  return (
    <MDBFooter
      className={className}
      style={{ ...style, zIndex: 2 }}
    >
      <p className='footer-copyright mb-0 py-3 text-center'>
        &copy; {date} Copyright: MDB React </p>
    </MDBFooter>
  )
}
export default (Copyrights: CopyrightsProps => React$Node)
